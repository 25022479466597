﻿@import 'variables';

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: $bga-black;
    opacity: 0.5;
}

.modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;

    .modal {
        border-top: solid 4px $bga-blue-light;
        z-index: 100;
        background: $bga-white;
        position: relative;
        margin: 1.75rem auto;
        border-radius: 3px;
        width: 700px;
        padding: 2rem;

        .modal-close {
            position: absolute;
            top: -$grid-gutter-width-half;
            right: 0;
            cursor: pointer;
        }
    }
}

@media (max-width: $screen-sm-max) {
    .modal-wrapper {
        .modal {
            width: auto;
        }
    }
}
