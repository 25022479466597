@import '../../styles/variables';

.event-details {
    .event-details-content-wrapper {
        max-width: 100%;
        border-left: solid 8px $bga-blue-border;

        .event-details-content {
            background: $bga-blue-background-extralight;

            .center-icon {
                display: flex;
                justify-content: center;

                .icon-wrapper {
                    width: 42px;
                    height: 42px;
                    background: $bga-white;
                    border-radius: 50%;
                    display: flex;
                    align-content: center;
                    justify-content: center;
                    flex-shrink: 0;
                    align-items: center;

                    .event-icon::before {
                        width: 20px;
                        height: 24px;
                        margin-right: 0 !important;
                    }
                }
            }

            .item {
                display: flex;
                margin-bottom: $grid-gutter-width-threequarters;

                .label {
                    font-weight: $font-weight-semibold;
                    min-width: 120px;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .event-link {
            background: $bga-blue-background-light;
            display: block;
        }
    }
}

@media (max-width: $screen-sm-max) {
    .event-details {
        .event-details-content-wrapper {
            .event-details-content {
                .item {
                    display: block;
                }
            }
        }
    }
}
