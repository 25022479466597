@import '../../styles/variables';

.video-player {
    position: relative;
    flex: 1;
    background-color: $bga-grey-lighter;
    border-bottom: 0.125rem solid $bga-blue-black-light;
    margin-bottom: $grid-gutter-width-threequarters;

    .video-player-header {
        padding: $grid-gutter-width-threequarters $grid-gutter-width-threequarters 0;
    }

    .video-player-player {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        padding-top: 56.25%;
        cursor: pointer;

        .video-player-player-youtube {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .video-player-button {
        background: transparent no-repeat;
        border: 0;
        height: 96px;
        width: 150px;
        margin: 0 auto;
        position: absolute;
        padding: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        background-image: url('../../assets/mediaButton.svg');

        &:hover {
            background-image: url('../../assets/mediaButtonHover.svg');
        }
    }

    .video-player-transcript {
        interpolate-size: allow-keywords;

        > details {
            padding: 0;
            position: relative;
            overflow: hidden;

            .arrow-down-icon {
                display: inline-flex;

                &::after {
                    transition: 0.25s linear;
                    margin: 0 $grid-gutter-width-threequarters 0 $grid-gutter-width-onequarter;
                }
            }

            &[open] {
                .transcript-toggle::after {
                    content: 'Close transcript';
                }

                .arrow-down-icon::after {
                    transform: scale(-1, -1);
                    transition: 0.25s linear;
                }

                &::details-content {
                    block-size: auto;
                }
            }

            &::details-content {
                block-size: 0;
                transition:
                    block-size 0.6s,
                    content-visibility 0.6s allow-discrete;
            }

            > summary {
                list-style: none;
                padding: $grid-gutter-width-half * 0.75 0;
                text-align: right;

                &::-webkit-details-marker {
                    display: none;
                }

                &:hover {
                    text-decoration: underline;
                }

                &:focus-visible {
                    box-shadow: inset 0 0 0 1px $bga-blue-background-dark;
                    border-radius: 4px;
                }

                > .transcript-toggle {
                    display: inline-flex;

                    &::after {
                        content: 'Open transcript';
                        text-decoration: underline;
                        font-weight: $font-weight-semibold;
                    }
                }

                + div {
                    max-height: 300px;
                    padding: $grid-gutter-width-threequarters;
                    overflow-y: auto;
                    border: 2px solid $bga-blue-black-light;
                    border-style: solid none none;

                    > * {
                        > li {
                            &:not(:last-child) {
                                margin-bottom: $grid-gutter-width-onequarter !important;
                            }
                        }

                        &:last-child {
                            margin-bottom: 0 !important;
                            padding-bottom: 0 !important;

                            > li:last-child {
                                margin-bottom: 0 !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-sm) {
    .video-player {
        .video-player-button {
            bottom: 0;
            transform: translate(-50%, -50%);
        }
    }
}
