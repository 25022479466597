@import '../../../styles/variables';

.hero-pathway-wrapper {
    margin-bottom: $grid-gutter-width;

    a.hero-pathway-anchor {
        text-decoration: none !important;
        display: block;
        height: 100%;
        border: 1px solid $bga-blue-border;

        .hero-pathway-card {
            height: 100%;
            min-height: 13rem;
            border-left-style: solid;
            border-left-width: 16px;

            .hero-pathway-card-content {
                display: flex;
                color: $bga-black;
                height: 100%;

                .hero-pathway-card-content-left {
                    flex: 1;
                    width: 50%;
                    display: flex;
                    flex-direction: column;
                    padding: $grid-gutter-width-threequarters;
                    align-content: space-between;

                    .hero-pathway-card-text {
                        padding-bottom: $grid-gutter-width;

                        h3 {
                            color: $bga-blue-links;
                        }

                        .hero-pathway-card-description {
                            p:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }

                    .hero-pathway-footer {
                        margin-top: auto;
                        display: flex;
                        align-items: center;

                        .circle-background {
                            width: 48px;
                            height: 48px;
                            border-radius: 50px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-shrink: 0;
                            z-index: 10;
                        }

                        .button-title {
                            min-height: 48px;
                            border-radius: 50px;
                            padding-left: $grid-gutter-width * 1.75 !important;
                            margin-left: -($grid-gutter-width * 1.5);
                            display: flex;
                            align-items: center;
                            text-align: center;

                            .button-text {
                                margin-top: auto;
                                margin-bottom: auto;
                                padding-top: $grid-gutter-width * 0.125;
                                padding-bottom: $grid-gutter-width * 0.125;
                                font-size: 0.88rem;
                                line-height: 18px;
                                font-weight: $font-weight-semibold;
                                word-wrap: break-word;
                                padding-right: $grid-gutter-width-threequarters;
                            }

                            &:hover {
                                background-color: $bga-white !important;
                            }
                        }
                    }
                }

                .hero-pathway-card-content-right {
                    padding: 0;
                    display: block;
                    width: 50%;

                    .hero-pathway-image {
                        width: 100%;
                        height: 100%;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }
        }

        &:hover,
        &:active,
        &:focus {
            cursor: pointer;
            background-color: $bga-teal-tool-extra-light;
            box-shadow: none !important;

            .hero-pathway-card {
                .hero-pathway-card-content {
                    .hero-pathway-card-content-left {
                        .hero-pathway-card-title {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md-max) {
    .hero-pathway-wrapper {
        a.hero-pathway-anchor {
            .hero-pathway-card {
                border-left-width: 8px;

                .hero-pathway-card-content {
                    .hero-pathway-card-content-left {
                        width: 100%;
                    }

                    .hero-pathway-card-content-right {
                        display: none;
                    }
                }
            }
        }
    }
}
