﻿@import 'variables';

@mixin tags() {
    .tag {
        padding: $grid-gutter-width-onequarter * 0.5 $grid-gutter-width-onequarter;
        line-height: 18px;
        font-size: $font-size-base * 0.75 !important;
        border-radius: 4px;
        display: flex;

        div {
            line-height: 18px;
            font-size: $font-size-base * 0.75 !important;
            align-self: center;
        }

        &.location-tag,
        &.tutorial-tag,
        &.guide-tag,
        &.options-tag,
        &.success_story-tag,
        &.news-tag,
        &.blog-tag,
        &.webinar-tag {
            background-color: $bga-blue-background-extralight;
        }

        &.tool-tag,
        &.quiz-tag,
        &.template-tag {
            background-color: $bga-teal-highlight-light;
        }

        &.date-tag,
        &.event-tag,
        &.on-demand-tag {
            background-color: $bga-yellow-highlight-light;
        }

        &.organisation-tag {
            background-color: $bga-green-highlight-light;
        }

        &.checklist-tag {
            background-color: $bga-purple-highlight-light;
        }
    }
}
